.Heading{
    text-align: center;
    background-color: rgba(104, 29, 29, 0.75);
    border-radius: 4px;
    z-index: 1;
    margin-bottom: 8px;
    margin-top: 5px;
}

.Gallery {
    margin: 10px;
    margin-bottom: 5vh;
    z-index: 5;
    display: inline-block;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.GalleryElement img{
    border: 3px solid rgba(104, 29, 29, 1);
    margin-top: 0px;
    opacity: 0.8;
    width: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
    
}

.GalleryContainer{
    display: flex;
    /* flex: 1; */
    flex-flow: column wrap;
    align-content: space-between;
    width:100%;

    /* Your container needs a fixed height, and it 
    * needs to be taller than your tallest column. */
    height: 4000px;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.GalleryElement:hover:not([class*=" disabled"]) img {
    opacity: 0.3;
}
  
.GalleryElement:hover .GalleryInfo {
    opacity: 1;
}

.GalleryInfo {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: calc(50% + 5px);
  left: calc(50% + 5px);
  width: 90%;
  line-height: 1.6;
  transform: translate(-50%, -50%);
  text-align: center;
  border: 2px solid #760419;
}

.ElementTitle {
    background-color: #100F21;
    color: #F0E9E5;
    font-size: 16px;
}

.ElementContent {
    background-color: #760419;
    color: #F0E9E5;
    font-size: 16px;
}

.ElementContent a:hover {
    color: #F40D01;
}

.Spacer {
    margin-bottom: 10px;
}

.DarkText {
    color: black;
}

.GalleryElement {
    width: 16%;
    position: relative;
    margin-bottom: 5px;
}

.GalleryElement:nth-of-type(6n+1) { order: 1; }
.GalleryElement:nth-of-type(6n+2) { order: 2; }
.GalleryElement:nth-of-type(6n+3) { order: 3; }
.GalleryElement:nth-of-type(6n+4) { order: 4; }
.GalleryElement:nth-of-type(6n+5) { order: 5; }
.GalleryElement:nth-of-type(6n)   { order: 6; }

.break {
    flex-basis: 100%;
    width: 0;
    /* border: 1px solid #ddd; */
    margin: 0;
    content: "";
    padding: 0;
}

@media (max-width: 1600px){
    .GalleryContainer{
        height: 5000px;
    }
    .GalleryElement{
        width: 19%;
    }
    .break{
        width: 0;
    }

    .GalleryElement:nth-of-type(5n+1) { order: 1; }
    .GalleryElement:nth-of-type(5n+2) { order: 2; }
    .GalleryElement:nth-of-type(5n+3) { order: 3; }
    .GalleryElement:nth-of-type(5n+4) { order: 4; }
    .GalleryElement:nth-of-type(5n)   { order: 5; }
}
  
@media (max-width: 1200px) {
    .GalleryContainer{
        height: 7500px;
    }
    .GalleryElement{
        width: 24%;
    }
    .break{
        width: 0;
    }

    .GalleryElement:nth-of-type(4n+1) { order: 1; }
    .GalleryElement:nth-of-type(4n+2) { order: 2; }
    .GalleryElement:nth-of-type(4n+3) { order: 3; }
    .GalleryElement:nth-of-type(4n)   { order: 4; }
  }
  @media (max-width: 1000px) {
    .GalleryContainer{
        height: 7500px;
    }
    .GalleryElement{
        width: 32%;
    }
    .break{
        width: 0;
    }

    .GalleryElement:nth-of-type(3n+1) { order: 1; }
    .GalleryElement:nth-of-type(3n+2) { order: 2; }
    .GalleryElement:nth-of-type(3n)   { order: 3; }
  }
  @media (max-width: 800px) {
    .GalleryContainer{
        height: 11000px;
    }
    .GalleryElement{
        width: 48%;
    }
    .break{
        width: 0;
    }
    .GalleryElement:nth-of-type(2n+1) { order: 1; }
    .GalleryElement:nth-of-type(2n)   { order: 2; }
  }
  @media (max-width: 500px) {
    .GalleryContainer{
        height: 28000px;
    }
    .GalleryElement{
        width: 98%;
    }
    .break{
        width: 0;
    }
    .GalleryElement:nth-of-type(2n)   { order: 1; }
  }
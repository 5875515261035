/* //---------------------//
//------Home-Page------//
//---------------------// */

/* // container for all the headings on the homepage */
.Home{
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.HomeContainer{
    display:flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    width: 90%;
    margin: 0;
    /* height: 100vw; */
    /* flex-basis: 1; */
    overflow-x: hidden;
}

.bg-lazy {
    /* height: 100vh;
    width: 100vw; */
    transition: opacity 0.5s ease-in;
}

.bg-loading {
    opacity: 0;
}

/* //container for my photo on the home page, has a vignette and my photo, resized to get the right effect */
.HeadshotContainer{
    background: 
        radial-gradient(transparent 0%, black 95%),
        url("../images/Headshot.jpg");
    background-size: 300px 300px;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    border: solid rgb(104, 29, 29) 5px;
    z-index: 9999;
}

.HomeInfo {
    position: relative;
    top: -40px;
    border: 5px solid rgb(104, 29, 29);
    background-color: rgb(104, 29, 29);
    padding-top: 20px;
    text-align: center;
    border-radius: 15px;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.HomeTitle {
    background-color: rgb(104, 29, 29);
    color: #F0E9E5;
    margin: 0;
    padding: 12px 12px;
}

.HomeContent {
    background-color: #100F21;
    color: #F0E9E5;
    font-size: 16px;
    margin: 0;
    padding: 16px 16px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

@keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

@media (max-width: 500px){
    h1{
        font-size: 20px;
    }
    h2{
        font-size: 10px;
    }
    .HeadshotContainer{
        height: 200px;
        width: 200px;
        background-size: 220px 220px;
    }
}
*{
  color: #F0E8E5;
  overflow-wrap: anywhere;
  word-break: break-word;
}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    z-index: -100;
    /* overflow-x: hidden;  */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Container{
  display:flex;
  width: 100%;
  /* overflow-x: hidden; */
  flex-direction: column;
  background-color: black;
  position:absolute;
  margin-top: 5vh;
  height: auto;
  margin-bottom: 5vh;
  /* z-index: -5; */
  flex-basis: 1;

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
}

.cont-lazy {
  /* height: 100vh;
  width: 100vw; */
  opacity: 0;
  transition: opacity 1s ease-out;
}

.cont-loading {
  opacity: 1;
}

.Container:before {
  background-image: url("https://prof-website.s3-ap-southeast-2.amazonaws.com/background.jpg");
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  /* z-index: -1; */
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-attachment: fixed;
  background-size: cover;
}

.Container > div {
  flex: 1;
}

.ContentContainer {
  display: flex;
  flex-basis: 1;
  height: 100%;
  z-index: 0;
}

.ContentContainer > div {
  flex: 1;
}

.HyperGold:hover {
  color: #F40D01;
}

/*Eliminates padding, centers the thumbnail */

/* body, html {
    padding: 0;
    margin: 0;
    text-align: center;
    } */
    
    /* Styles the thumbnail */
    
    /* Styles the lightbox, removes it from sight and adds the fade-in transition */
    
    .lightbox-target {
        position: absolute;

        top: 0;
        left: 0;
        width:100%;
        /* height: 50vw; */
        background: rgba(0,0,0,.7);
        /* width: 100%; */
        opacity: 1;
        -webkit-transition: opacity .5s ease-in-out;
        -moz-transition: opacity .5s ease-in-out;
        -o-transition: opacity .5s ease-in-out;
        transition: opacity .5s ease-in-out;
        overflow: hidden;
    }
    
    /* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */
    
    .lightbox-target img {
        margin: auto;
        position: absolute;
        top: 10vh;
        left:0;
        /* right:0; */
        /* bottom: 0; */
        max-height: 0;
        max-width: 0;
        border: 3px solid white;
        box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
        box-sizing: border-box;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        z-index: 500;
    }
    
    /* Styles the close link, adds the slide down transition */
    
    a.lightbox-close {
        display: block;
        width:100%;
        height:100%;
        box-sizing: border-box;
        /* background: white;
        color: black; */
        text-decoration: none;
        position: absolute;
        /* top: 50%;
        right: 50%; */
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        z-index: 505;
    }
    
    /* Provides part of the "X" to eliminate an image from the close link */
    
    a.lightbox-close:before {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    /* background: black; */
    position: absolute;
    left: 26px;
    top:10px;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg);
    }
    
    /* Provides part of the "X" to eliminate an image from the close link */
    
    a.lightbox-close:after {
        content: "";
        display: block;
        height: 30px;
        width: 1px;
        /* background: black; */
        position: absolute;
        left: 26px;
        top:10px;
        -webkit-transform:rotate(-45deg);
        -moz-transform:rotate(-45deg);
        -o-transform:rotate(-45deg);
        transform:rotate(-45deg);
    }
    
    /* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */
    
    .lightbox-target:target {
    opacity: 1;
    top: 0;
    bottom: 0;
    }
    
    .lightbox-target:target img {
        left: 15%;
        max-height: 90%;
        max-width: 70%;
    }
    
    .lightbox-target:target a.lightbox-close {
        top: 0px;
    }
    
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  color: #F0E8E5;
  overflow-wrap: anywhere;
  word-break: break-word;
}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    z-index: -100;
    /* overflow-x: hidden;  */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Container{
  display:-webkit-flex;
  display:flex;
  width: 100%;
  /* overflow-x: hidden; */
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: black;
  position:absolute;
  margin-top: 5vh;
  height: auto;
  margin-bottom: 5vh;
  /* z-index: -5; */
  -webkit-flex-basis: 1;
          flex-basis: 1;

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
       animation: fadein 1s;
}

.cont-lazy {
  /* height: 100vh;
  width: 100vw; */
  opacity: 0;
  transition: opacity 1s ease-out;
}

.cont-loading {
  opacity: 1;
}

.Container:before {
  background-image: url("https://prof-website.s3-ap-southeast-2.amazonaws.com/background.jpg");
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  /* z-index: -1; */
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-attachment: fixed;
  background-size: cover;
}

.Container > div {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.ContentContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-basis: 1;
          flex-basis: 1;
  height: 100%;
  z-index: 0;
}

.ContentContainer > div {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.HyperGold:hover {
  color: #F40D01;
}

.Component {
    /* border: 5px solid green; */
    /* min-height: 25px; */
    background-color: rgb(104, 29, 29);
    height: 5vh;
    z-index: 9999;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    /* justify-content: center; */
}

.ComponentFooter {
    /* border: 5px solid green; */
    /* min-height: 25px; */
    background-color: rgb(104, 29, 29);
    height: 5vh;
    z-index: 9999;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Component h3 {
    padding: 20px;
}

/* [ON BIG SCREEN] */
/* Wrapper */
#hamnav {
    /* max-width: 120px; */
    background: #100F21;
    /* Optional */
    position: -webkit-sticky;
    position: sticky;
    /* position: -webkit-sticky; */
    top: 5vh;
    height: 95vh;
    
}

/* Hide Hamburger */
#hamnav label, #hamburger { 
    display: none;  
    width: 100%;
    box-sizing: border-box;
}

/* Column Menu Items */
#hamitems { 
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width:100%;
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 90vh;
    /* z-index: 99999; */
}

#hamitems .intraNav { 
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    width:100%;
    /* z-index: 99999; */
}

#hamitems .extraNav { 
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:100%;
    /* z-index: 99999; */

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
        animation: fadein 1s;
}
#hamitems a {
    /* width: calc(100% - 10px); /* 100% / 5 tabs = 20% */
    padding: 10px; 
    /* color: white; */
    text-decoration: none;
    text-align: center;
    border-top: 1px solid #373E5B;
}

#hamitems a:hover {
background: rgba(104, 29, 29, 1);
/* display: block; */
}

/* [ON SMALL SCREENS] */
@media screen and (max-width: 768px){
/* Show Hamburger Icon */
    #hamnav label { 
        display: inline-block; 
        /* color: white; */
        border: solid rgb(104, 29, 29);
        /* border-top: 0; */
        border-width: 0 2px 2px 2px;
        background: rgb(104, 29, 29);
        font-style: normal;
        font-size: 1.2em;
        padding: 10px;
    }

    /* Break down menu items into vertical */
    #hamitems a {
        box-sizing: border-box;
        display: block;
        width: 100%;
        border-top: 1px solid #373E5B;
    }

    /* Toggle Show/Hide Menu */
    #hamitems { display: none; }
    #hamnav input:checked ~ #hamitems{ 
        display: -webkit-flex; 
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width:100%;
        -webkit-flex: 1 1;
                flex: 1 1;
        height: 82.5vh;
        /* z-index: 99999; */
    }
    /* #hamnav input:checked ~ #hamitems label{ width: 100%} */
}
.Heading{
    text-align: center;
    background-color: rgba(104, 29, 29, 0.75);
    border-radius: 4px;
    z-index: 1;
    margin-bottom: 8px;
    margin-top: 5px;
}

.Gallery {
    margin: 10px;
    margin-bottom: 5vh;
    z-index: 5;
    display: inline-block;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
            animation: fadein 1s;
}

.GalleryElement img{
    border: 3px solid rgba(104, 29, 29, 1);
    margin-top: 0px;
    opacity: 0.8;
    width: 100%;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    
}

.GalleryContainer{
    display: -webkit-flex;
    display: flex;
    /* flex: 1; */
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    -webkit-align-content: space-between;
            align-content: space-between;
    width:100%;

    /* Your container needs a fixed height, and it 
    * needs to be taller than your tallest column. */
    height: 4000px;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
            animation: fadein 1s;
}

.GalleryElement:hover:not([class*=" disabled"]) img {
    opacity: 0.3;
}
  
.GalleryElement:hover .GalleryInfo {
    opacity: 1;
}

.GalleryInfo {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: calc(50% + 5px);
  left: calc(50% + 5px);
  width: 90%;
  line-height: 1.6;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  border: 2px solid #760419;
}

.ElementTitle {
    background-color: #100F21;
    color: #F0E9E5;
    font-size: 16px;
}

.ElementContent {
    background-color: #760419;
    color: #F0E9E5;
    font-size: 16px;
}

.ElementContent a:hover {
    color: #F40D01;
}

.Spacer {
    margin-bottom: 10px;
}

.DarkText {
    color: black;
}

.GalleryElement {
    width: 16%;
    position: relative;
    margin-bottom: 5px;
}

.GalleryElement:nth-of-type(6n+1) { -webkit-order: 1; order: 1; }
.GalleryElement:nth-of-type(6n+2) { -webkit-order: 2; order: 2; }
.GalleryElement:nth-of-type(6n+3) { -webkit-order: 3; order: 3; }
.GalleryElement:nth-of-type(6n+4) { -webkit-order: 4; order: 4; }
.GalleryElement:nth-of-type(6n+5) { -webkit-order: 5; order: 5; }
.GalleryElement:nth-of-type(6n)   { -webkit-order: 6; order: 6; }

.break {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    width: 0;
    /* border: 1px solid #ddd; */
    margin: 0;
    content: "";
    padding: 0;
}

@media (max-width: 1600px){
    .GalleryContainer{
        height: 5000px;
    }
    .GalleryElement{
        width: 19%;
    }
    .break{
        width: 0;
    }

    .GalleryElement:nth-of-type(5n+1) { -webkit-order: 1; order: 1; }
    .GalleryElement:nth-of-type(5n+2) { -webkit-order: 2; order: 2; }
    .GalleryElement:nth-of-type(5n+3) { -webkit-order: 3; order: 3; }
    .GalleryElement:nth-of-type(5n+4) { -webkit-order: 4; order: 4; }
    .GalleryElement:nth-of-type(5n)   { -webkit-order: 5; order: 5; }
}
  
@media (max-width: 1200px) {
    .GalleryContainer{
        height: 7500px;
    }
    .GalleryElement{
        width: 24%;
    }
    .break{
        width: 0;
    }

    .GalleryElement:nth-of-type(4n+1) { -webkit-order: 1; order: 1; }
    .GalleryElement:nth-of-type(4n+2) { -webkit-order: 2; order: 2; }
    .GalleryElement:nth-of-type(4n+3) { -webkit-order: 3; order: 3; }
    .GalleryElement:nth-of-type(4n)   { -webkit-order: 4; order: 4; }
  }
  @media (max-width: 1000px) {
    .GalleryContainer{
        height: 7500px;
    }
    .GalleryElement{
        width: 32%;
    }
    .break{
        width: 0;
    }

    .GalleryElement:nth-of-type(3n+1) { -webkit-order: 1; order: 1; }
    .GalleryElement:nth-of-type(3n+2) { -webkit-order: 2; order: 2; }
    .GalleryElement:nth-of-type(3n)   { -webkit-order: 3; order: 3; }
  }
  @media (max-width: 800px) {
    .GalleryContainer{
        height: 11000px;
    }
    .GalleryElement{
        width: 48%;
    }
    .break{
        width: 0;
    }
    .GalleryElement:nth-of-type(2n+1) { -webkit-order: 1; order: 1; }
    .GalleryElement:nth-of-type(2n)   { -webkit-order: 2; order: 2; }
  }
  @media (max-width: 500px) {
    .GalleryContainer{
        height: 28000px;
    }
    .GalleryElement{
        width: 98%;
    }
    .break{
        width: 0;
    }
    .GalleryElement:nth-of-type(2n)   { -webkit-order: 1; order: 1; }
  }
.LoadingContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #F0E9E5;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes lds-ellipsis1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@-webkit-keyframes lds-ellipsis3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}
@-webkit-keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(24px, 0);
                transform: translate(24px, 0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(24px, 0);
                transform: translate(24px, 0);
    }
}

.Heading{
    text-align: center;
    /* position: relative; */
    /* background-color: rgba(55, 62, 91, 0.75); */
    background-color: rgba(104, 29, 29, 0.75);
    border-radius: 4px;
    /* justify-self: center; */
    /* width: 50%; */
    /* opacity: 1; */
    z-index: 1;
    margin-bottom: 8px;
    margin-top: 5px;
}

.Projects {
    margin: 10px;
    /* margin-bottom: 5vh; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 5;
    display: inline-block;
    /* overflow-y:scroll; */
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
         animation: fadein 1s;
}

.ProjectElement {
    width: 100%;
    /* display: inline-block; */
    /* height: auto; */
    /* position: relative; */
    /* z-index: -1; */
}

.ProjectElement img{
    border: 3px solid rgba(104, 29, 29, 1);
    /* padding: 5px; */
    /* margin: 10px; */
    opacity: 0.8;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    /* min-height: 25px;  */
}

.ProjGalleryContainer {
    /* border: 5px solid blue; */
    /* line-height: 0; */
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;  
    display: inline-block;
    /* margin: 10px; */
    margin-bottom: 0px;
    /* margin-right: 15px; */
    /* width: calc(100% - 5px); */
    /* margin-bottom: 35px; */
    /* min-height: 25px;  */
}

.ProjectsContainer {
    /* border: 5px solid blue; */
    /* line-height: 0;
    column-count: 5;
    column-gap: 10px;  
    display: inline-block;
    /* margin: 5px; */
    /* width: calc(100% - 9px); */
    margin-bottom: 5vh;
    /* min-height: 25px;  */

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
            animation: fadein 1s;
}

/* .ProjectElement:hover img {
    /* opacity: 0.3; */

  
.ProjectElement:hover .GalleryInfo {
    opacity: 1;
}

.ProjectInfo {
    border: 2px solid #760419;
    margin-top: 0px;
    margin-bottom: 10px;
}

.ProjElementTitle {
    background-color: #100F21;
    color: #F0E9E5;
    font-size: 16px;
    padding: 16px 16px;
}

.ProjElementContent {
    background-color: #760419;
    color: #F0E9E5;
    font-size: 16px;
    padding: 16px 16px;
}

.ProjElementContent a:hover {
    color: #F40D01;
}

.Spacer {
    margin-bottom: 10px;
}

@keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */
  
@media (max-width: 1200px) {
    .ProjGalleryContainer {
        -webkit-column-count:         3;
                column-count:         3;
    }
  }
  @media (max-width: 1000px) {
    .ProjGalleryContainer {
        -webkit-column-count:         2;
                column-count:         2;
    }
  }
  @media (max-width: 800px) {
    .ProjGalleryContainer {
        -webkit-column-count:         1;
                column-count:         1;
    }
  }
  /* @media (max-width: 400px) {
    .ProjGalleryContainer {
        column-count:         1;
    }
  } */
/*Eliminates padding, centers the thumbnail */

/* body, html {
    padding: 0;
    margin: 0;
    text-align: center;
    } */
    
    /* Styles the thumbnail */
    
    /* Styles the lightbox, removes it from sight and adds the fade-in transition */
    
    .lightbox-target {
        position: absolute;

        top: 0;
        left: 0;
        width:100%;
        /* height: 50vw; */
        background: rgba(0,0,0,.7);
        /* width: 100%; */
        opacity: 1;
        transition: opacity .5s ease-in-out;
        overflow: hidden;
    }
    
    /* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */
    
    .lightbox-target img {
        margin: auto;
        position: absolute;
        top: 10vh;
        left:0;
        /* right:0; */
        /* bottom: 0; */
        max-height: 0;
        max-width: 0;
        border: 3px solid white;
        box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
        box-sizing: border-box;
        transition: .5s ease-in-out;
        z-index: 500;
    }
    
    /* Styles the close link, adds the slide down transition */
    
    a.lightbox-close {
        display: block;
        width:100%;
        height:100%;
        box-sizing: border-box;
        /* background: white;
        color: black; */
        text-decoration: none;
        position: absolute;
        /* top: 50%;
        right: 50%; */
        transition: .5s ease-in-out;
        z-index: 505;
    }
    
    /* Provides part of the "X" to eliminate an image from the close link */
    
    a.lightbox-close:before {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    /* background: black; */
    position: absolute;
    left: 26px;
    top:10px;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
    }
    
    /* Provides part of the "X" to eliminate an image from the close link */
    
    a.lightbox-close:after {
        content: "";
        display: block;
        height: 30px;
        width: 1px;
        /* background: black; */
        position: absolute;
        left: 26px;
        top:10px;
        -webkit-transform:rotate(-45deg);
        transform:rotate(-45deg);
    }
    
    /* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */
    
    .lightbox-target:target {
    opacity: 1;
    top: 0;
    bottom: 0;
    }
    
    .lightbox-target:target img {
        left: 15%;
        max-height: 90%;
        max-width: 70%;
    }
    
    .lightbox-target:target a.lightbox-close {
        top: 0px;
    }
    
.h-divider {
  margin: auto;
  margin-top: 20px;
  width: 99%;
  position: relative;
}

.h-divider .shadow {
  overflow: hidden;
  height: 20px;
}

.h-divider .shadow:after {
  content: "";
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}

.h-divider .text {
  width: 100px;
  height: 45px;
  padding: 10px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -33px;
  left: 50%;
  margin-left: -60px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #999;
  background: white;
}

.h-divider .text i {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 100%;
  border: 1px dashed #aaa;
  text-align: center;
  line-height: 50px;
  font-style: normal;
  color: #999;
}

.h-divider .text2 {
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -35px;
  left: 50%;
  margin-left: -25px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #999;
  background: white;
}

.h-divider img {
  position: absolute;
  margin: 4px;
  max-width: 60px;
  border-radius: 100%;
  border: 1px dashed #aaa;
}

/* //---------------------//
//------Home-Page------//
//---------------------// */

/* // container for all the headings on the homepage */
.Home{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 5;
}

.HomeContainer{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-items: center;
            align-items: center;
    width: 90%;
    margin: 0;
    /* height: 100vw; */
    /* flex-basis: 1; */
    overflow-x: hidden;
}

.bg-lazy {
    /* height: 100vh;
    width: 100vw; */
    transition: opacity 0.5s ease-in;
}

.bg-loading {
    opacity: 0;
}

/* //container for my photo on the home page, has a vignette and my photo, resized to get the right effect */
.HeadshotContainer{
    background: 
        radial-gradient(transparent 0%, black 95%),
        url(/static/media/Headshot.1a1e6b88.jpg);
    background-size: 300px 300px;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    border: solid rgb(104, 29, 29) 5px;
    z-index: 9999;
}

.HomeInfo {
    position: relative;
    top: -40px;
    border: 5px solid rgb(104, 29, 29);
    background-color: rgb(104, 29, 29);
    padding-top: 20px;
    text-align: center;
    border-radius: 15px;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
            animation: fadein 1s;
}

.HomeTitle {
    background-color: rgb(104, 29, 29);
    color: #F0E9E5;
    margin: 0;
    padding: 12px 12px;
}

.HomeContent {
    background-color: #100F21;
    color: #F0E9E5;
    font-size: 16px;
    margin: 0;
    padding: 16px 16px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

@keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */

@media (max-width: 500px){
    h1{
        font-size: 20px;
    }
    h2{
        font-size: 10px;
    }
    .HeadshotContainer{
        height: 200px;
        width: 200px;
        background-size: 220px 220px;
    }
}
.fallingLeaves{
    position: fixed;
    top: 0;
    /* z-index: 9999; */
}

.fallingLeaves span {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: -280px 40px 54px -50px;
        /* z-index: 9999; */

    background: 
    url(/static/media/leaf.5443255a.png);
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 50% 0;

    -webkit-filter: brightness(0) saturate(100%) invert(8%) sepia(74%) saturate(3490%) hue-rotate(350deg) brightness(106%) contrast(86%);

            filter: brightness(0) saturate(100%) invert(8%) sepia(74%) saturate(3490%) hue-rotate(350deg) brightness(106%) contrast(86%);

    animation: fallingLeaves 11s infinite linear;
    -webkit-animation: fallingLeaves 11s infinite linear;
    -moz-animation: fallingLeaves 11s infinite linear;
}
    
.fallingLeaves span:nth-child(6n+5) {
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
}
    
.fallingLeaves span:nth-child(6n+4) {
    animation-delay: 2.6s;
    -webkit-animation-delay: 2.6s;
    -moz-animation-delay: 2.6s;
}
.fallingLeaves span:nth-child(6n+3) {
    animation-delay: 6.7s;
    -webkit-animation-delay: 6.7s;
    -moz-animation-delay: 6.7s;
}
.fallingLeaves span:nth-child(6n+2) {
    animation-delay: 8.8s;
    -webkit-animation-delay: 8.8s;
    -moz-animation-delay: 8.8s;
}
.fallingLeaves span:nth-child(6n+1) {
    animation-delay: 10.9s;
    -webkit-animation-delay: 10.9s;
    -moz-animation-delay: 10.9s;
}
.fallingLeaves span:nth-child(6n) {
    animation-delay: 12.2s;
    -webkit-animation-delay: 12.2s;
    -moz-animation-delay: 12.2s;
}

@-webkit-keyframes fallingLeaves {
    0% {
        opacity: 1;
        transform: translate(0, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        -webkit-transform: translate(0, 0px) rotateZ(0deg);
    }
    75% {
        opacity: 1;
        transform: translate(100px, 600px) rotateX(270deg) rotateY(270deg) rotateZ(270deg);
        -webkit-transform: translate(100px, 600px) rotateZ(270deg);
    }
    100% {
        opacity: 0;
        transform: translate(150px, 800px) rotateX(360deg) rotateY(360deg) rotateZ(360deg);
        -webkit-transform: translate(150px, 800px) rotateZ(360deg);
    }
}

@keyframes fallingLeaves {
    0% {
        opacity: 1;
        transform: translate(0, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        -webkit-transform: translate(0, 0px) rotateZ(0deg);
    }
    75% {
        opacity: 1;
        transform: translate(100px, 600px) rotateX(270deg) rotateY(270deg) rotateZ(270deg);
        -webkit-transform: translate(100px, 600px) rotateZ(270deg);
    }
    100% {
        opacity: 0;
        transform: translate(150px, 800px) rotateX(360deg) rotateY(360deg) rotateZ(360deg);
        -webkit-transform: translate(150px, 800px) rotateZ(360deg);
    }
}

.Heading{
    text-align: center;
    /* position: relative; */
    /* background-color: rgba(55, 62, 91, 0.75); */
    background-color: rgba(104, 29, 29, 0.75);
    border-radius: 4px;
    /* justify-self: center; */
    /* width: 50%; */
    /* opacity: 1; */
    z-index: 1;
    margin-bottom: 8px;
    margin-top: 5px;
}

.Projects {
    margin: 10px;
    /* margin-bottom: 5vh; */
    height: fit-content;
    z-index: 5;
    display: inline-block;
    /* overflow-y:scroll; */
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
}

.ProjectElement {
    width: 100%;
    /* display: inline-block; */
    /* height: auto; */
    /* position: relative; */
    /* z-index: -1; */
}

.ProjectElement img{
    border: 3px solid rgba(104, 29, 29, 1);
    /* padding: 5px; */
    /* margin: 10px; */
    opacity: 0.8;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    /* min-height: 25px;  */
}

.ProjGalleryContainer {
    /* border: 5px solid blue; */
    /* line-height: 0; */
    column-count: 3;
    column-gap: 12px;  
    display: inline-block;
    /* margin: 10px; */
    margin-bottom: 0px;
    /* margin-right: 15px; */
    /* width: calc(100% - 5px); */
    /* margin-bottom: 35px; */
    /* min-height: 25px;  */
}

.ProjectsContainer {
    /* border: 5px solid blue; */
    /* line-height: 0;
    column-count: 5;
    column-gap: 10px;  
    display: inline-block;
    /* margin: 5px; */
    /* width: calc(100% - 9px); */
    margin-bottom: 5vh;
    /* min-height: 25px;  */

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

/* .ProjectElement:hover img {
    /* opacity: 0.3; */

  
.ProjectElement:hover .GalleryInfo {
    opacity: 1;
}

.ProjectInfo {
    border: 2px solid #760419;
    margin-top: 0px;
    margin-bottom: 10px;
}

.ProjElementTitle {
    background-color: #100F21;
    color: #F0E9E5;
    font-size: 16px;
    padding: 16px 16px;
}

.ProjElementContent {
    background-color: #760419;
    color: #F0E9E5;
    font-size: 16px;
    padding: 16px 16px;
}

.ProjElementContent a:hover {
    color: #F40D01;
}

.Spacer {
    margin-bottom: 10px;
}

@keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    0% { opacity: 0; }
    50%   { opacity: 0; }
    100%   { opacity: 1; }
}
  
@media (max-width: 1200px) {
    .ProjGalleryContainer {
        column-count:         3;
    }
  }
  @media (max-width: 1000px) {
    .ProjGalleryContainer {
        column-count:         2;
    }
  }
  @media (max-width: 800px) {
    .ProjGalleryContainer {
        column-count:         1;
    }
  }
  /* @media (max-width: 400px) {
    .ProjGalleryContainer {
        column-count:         1;
    }
  } */